import { withTheme } from "styled-components";

import Boarding from "./icons/services/boarding.svg";
import DogWalking from "./icons/services/dog-walking.svg";
import DayCare from "./icons/services/day-care.svg";
import DropInVisits from "./icons/services/drop-in-visits.svg";
import HouseSitting from "./icons/services/house-sitting.svg";

const serviceIcons = {
  "1": Boarding,
  "2": DogWalking,
  "3": HouseSitting,
  "7": DayCare,
  "9": DropInVisits
};

type ServiceTypeId = keyof typeof serviceIcons;

interface Props {
  serviceTypeId: number;
  [x: string]: any;
  fill: boolean;
}

const ServiceIcon = (props: Props) => {
  const { theme, serviceTypeId, color, fontSize, ...rest } = props;

  if (!serviceTypeId) return <></>;

  const Icon = serviceIcons[serviceTypeId.toString() as ServiceTypeId];

  return (
    <Icon
      color={theme.colors[color || "inuOrange"]}
      {...rest}
      fontSize={theme.fontSizes[fontSize] || "inherit"}
    />
  );
};

export default withTheme(ServiceIcon);
